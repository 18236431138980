<template>
  <div class="app" :class="'guide-'+guide">
    <AppHeader fixed>
      <b-link class="navbar-brand" to="#">
        <img class="navbar-brand-full" src="img/logo.png" width="89" height="25" alt="PIA Logo">
        <img class="navbar-brand-minimized" src="img/logo-symbol.png" width="30" height="30" alt="PIA Logo">
      </b-link>
      <div v-if="menuButton">
        <SidebarToggler class="d-lg-none" display="md" mobile />
        <SidebarToggler class="d-md-down-none" display="lg" />
      </div>
      <b-navbar-nav class="d-md-down-none">
        <b-nav-item class="px-3 exploitation-home" to="/home">Exploitations</b-nav-item>
        <b-nav-item class="px-3 exploitation-home" @click="changeGuide()">
          Mon Assistant
          <i :class="guideState" v-b-tooltip.hover="hoverGuideMessage"></i>
        </b-nav-item>
        <b-nav-item class="px-3 market-home" to="/market" target="_blank" exact>
          Marché
          <i class="fa fa-external-link"></i>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto pr-3">
        <b-button variant="outline-primary" class="invitation-home" @click="onInvitationsClick">
          <i class="icon-user-follow"></i>
          Invitations
        </b-button>
        <DefaultHeaderDropdownAccount />
      </b-navbar-nav>
    </AppHeader>
    <router-view @showMenuButton="showMenuButton" @hideMenuButton="hideMenuButton"></router-view>
    <TheFooter>
      <div>
        Copyrights
        <span class="ml-1">&copy; {{(new Date()).getFullYear()}} Kratos Security</span>
      </div>
    </TheFooter>
  </div>
</template>

<script>
  import {
    Header as AppHeader,
    Footer as TheFooter,
    Breadcrumb,
    SidebarToggler
  } from '@coreui/vue'
  import DefaultHeaderDropdownAccount from './DefaultHeaderDropdownAccount'
  import {mapState} from 'vuex'
  import {Store} from "../helpers";
  
  export default {
    name: 'DefaultContainer',
    components: {
      AppHeader,
      TheFooter,
      Breadcrumb,
      SidebarToggler,
      DefaultHeaderDropdownAccount
    },
    computed: {
      ...mapState([
        'guide'
      ]),
      name() {
        return this.$route.name
      },
      list() {
        return this.$route.matched.filter((route) => route.name || route.meta.label)
      },
      guideState() {
        let guideStateIcon = "fa "
        if (this.guide)
          guideStateIcon += "fa-stop-circle text-danger"
        else guideStateIcon += "fa-play text-success"
        
        return guideStateIcon
      },
      hoverGuideMessage(){
        if(this.guide)
          return "Arreter l'asssitant"
        else return "Lancer l'Assistant"
      }
    },
    data() {
      return {
        menuButton: false,
      }
    },
    methods: {
      showMenuButton() {
        this.menuButton = true
      },
      hideMenuButton() {
        this.menuButton = false
      },
      onInvitationsClick() {
        this.$router.push({name: 'Invitations'})
      },
      changeGuide(){
        this.$store.commit('setGuideState')
      }
    }
  }
</script>
<style>
  
  .toasted-container.top-right{
    top: 5%;
    right: 6%;
  }
  .toasted-container{
    z-index: 1030 !important;
  }
  .toasted-container .toasted{
    max-width: 800px;
    padding: 35px 15px;
    display: block;
    background-color: rgb(250, 250, 250);
  }
  .toasted-container .toasted::before{
    content: "Assistant";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #e4e4e4;
    padding: 4px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 7px;
    font-family: "Segoe UI" !important;
  }
  .toasted-container .toasted .fa{
    margin-left:.7rem;
  }
  .toasted.outline{
    border-radius: 10px;
    box-shadow: 0 10px 5px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);
    font-family: Candara !important;
  }
  .toasted-container .toasted .action:first-of-type::before{
  
  }
  .toasted-container .toasted .action{
    margin: 10px;
    letter-spacing: 0;
    padding: 5px !important;
    border: 1px solid #eaeaea;
    display: inline-block;
    background-color: rgba(134, 214, 134, 0.67);
    border-radius: 5px;
    color: #888888;
  }
  .guide-container{
    display: table !important;
  }

  .guide-false ~ .toasted-container {
    display: none;
  }


</style>
