<template>
  <AppHeaderDropdown right no-caret class="mx-3">
    <template slot="header">
      <div class="user-icon-container user-home">
        <i class="icon-user"></i>
      </div>
      <b-badge pill variant="warning" v-if="notificationsCount">{{ notificationsCount }}</b-badge>
    </template>
    \
    <template slot="dropdown">
      <b-dropdown-header tag="div" class="text-center">
        <strong>Notifications</strong>
      </b-dropdown-header>
      <b-dropdown-item to="/notifications">
        <i class="fa fa-bell-o"></i> Notifications
        <b-badge variant="danger" v-if="alertsCount">{{ alertsCount }}</b-badge>
        <b-badge variant="success" v-if="messagesCount">{{ messagesCount }}</b-badge>
      </b-dropdown-item>

      <b-dropdown-header tag="div" class="text-center">
        <strong>Mon Compte</strong>
      </b-dropdown-header>
      <b-dropdown-item to="/profile">
        <i class="fa fa-user"></i> Mon Profil
      </b-dropdown-item>
      <b-dropdown-item to="/logout">
        <i class="fa fa-power-off"></i> Déconnexion
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
  import {HeaderDropdown as AppHeaderDropdown} from '@coreui/vue'

  export default {
    name: 'DefaultHeaderDropdownAccnt',
    components: {
      AppHeaderDropdown
    },
    computed: {
      notificationsCount() {
        return this.$store.getters.notificationsCount
      },
      messagesCount() {
        return this.$store.getters.messagesCount
      },
      alertsCount() {
        return this.$store.getters.alertsCount
      }
    }
  }
</script>

<style scoped>
  .user-icon-container {
    border: 1px solid #bebebe;
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 50%;
    padding-top: 4px;
    background-color: #f5f5f5;
  }

  .dropdown-item .badge {
    position: unset;
    margin-left: 2px;
    margin-right: 0;
    float: right;
  }
</style>
